// extracted by mini-css-extract-plugin
export var Img1 = "styles-module--Img1--4b8ec";
export var Img1Main = "styles-module--Img1Main--ce064";
export var Img2 = "styles-module--Img2--d4224";
export var Img2Main = "styles-module--Img2Main--cd1d8";
export var aboutDesc = "styles-module--aboutDesc--c3094";
export var aboutImgs = "styles-module--aboutImgs--8f033";
export var aboutImgsInner = "styles-module--aboutImgsInner--0b8eb";
export var aboutSectionInner = "styles-module--aboutSectionInner--230e3";
export var agreeLabel = "styles-module--agreeLabel--0daf2";
export var agreeText = "styles-module--agreeText--358e1";
export var applicationDesc = "styles-module--applicationDesc--ed283";
export var applicationTitle = "styles-module--applicationTitle--58e17";
export var apply1Img = "styles-module--apply1Img--50a7d";
export var apply2Img = "styles-module--apply2Img--310c6";
export var applyForm = "styles-module--applyForm--0bbc5";
export var applyFormInner = "styles-module--applyFormInner--f5b88";
export var applyHead = "styles-module--applyHead--34276";
export var applyImg = "styles-module--applyImg--4dee6";
export var applyImgs = "styles-module--applyImgs--1172c";
export var applyLine = "styles-module--applyLine--4e246";
export var applySendButton = "styles-module--applySendButton--475e4";
export var curveArrowImg = "styles-module--curveArrowImg--c78cb";
export var curveArrowMain = "styles-module--curveArrowMain--69908";
export var descImg = "styles-module--descImg--5d345";
export var descImgMain = "styles-module--descImgMain--fc11f";
export var descNo = "styles-module--descNo--df422";
export var descTitleMain = "styles-module--descTitleMain--287df";
export var descTxt = "styles-module--descTxt--592f0";
export var descTxtMain = "styles-module--descTxtMain--71fd9";
export var emailBtn = "styles-module--emailBtn--0a7fb";
export var emailDesc = "styles-module--emailDesc--eafbc";
export var emailHead = "styles-module--emailHead--53997";
export var emailImage = "styles-module--emailImage--42ffc";
export var emailImageMain = "styles-module--emailImageMain--d20b3";
export var emailInner = "styles-module--emailInner--e74ee";
export var emailSec = "styles-module--emailSec--a534b";
export var emailTxt = "styles-module--emailTxt--e034d";
export var footerList = "styles-module--footerList--5fd8b";
export var footerMain = "styles-module--footerMain--21316";
export var forApplyWidth = "styles-module--forApplyWidth--50adf";
export var heroDiv = "styles-module--heroDiv--c45c0";
export var heroMain = "styles-module--heroMain--365af";
export var heroTxtMain = "styles-module--heroTxtMain--1bd8f";
export var howWorks = "styles-module--howWorks--c9f95";
export var howWorksInner = "styles-module--howWorksInner--42fd7";
export var inputField = "styles-module--inputField--f0a70";
export var inputMain = "styles-module--inputMain--16b65";
export var leafImg = "styles-module--leafImg--44ee2";
export var leftCardBtn = "styles-module--leftCardBtn--5b9cf";
export var listContent = "styles-module--listContent--12830";
export var listHead = "styles-module--listHead--1e715";
export var listLink = "styles-module--listLink--e98bf";
export var listLinkMain = "styles-module--listLinkMain--2dfcf";
export var listLinks = "styles-module--listLinks--a9bee";
export var listTxt = "styles-module--listTxt--0e41f";
export var modelBody = "styles-module--modelBody--b1876";
export var providerSignUpMain = "styles-module--providerSignUpMain--95f77";
export var qualIcon = "styles-module--qualIcon--6b028";
export var qualNote = "styles-module--qualNote--cdf1a";
export var qualTitle = "styles-module--qualTitle--66dac";
export var qualTxt = "styles-module--qualTxt--9620a";
export var qualification = "styles-module--qualification--aa75e";
export var signInLink = "styles-module--signInLink--efaa6";
export var signInTxt = "styles-module--signInTxt--71e73";
export var titleDetail1 = "styles-module--titleDetail1--1609d";
export var titleDetail2 = "styles-module--titleDetail2--1fc70";
export var titleDetails = "styles-module--titleDetails--5e859";
export var titleLine = "styles-module--titleLine--66217";
export var titleTxt = "styles-module--titleTxt--34560";