import React, { useEffect } from "react"
import * as styles from "./styles.module.scss"

import ProfileInformation from "../../../assets/images/PAGE_ Account - Therapist - Profile Information - About 2.png"
import Card from "../../../assets/images/CARDS.png"
import Decor from "../../../assets/images/Decor.png"
import curveArrow from "../../../assets/images/black-arrow-curved-hi.png"
import leaf from "../../../assets/images/leaf.png"
import apply1 from "../../../assets/images/apply1.png"
import apply2 from "../../../assets/images/apply2.png"
import Asset1 from "../../../assets/images/Asset1.png"

import axios from "axios"
import Modal from "react-bootstrap/Modal"
import { Button, Col, Container, Row } from "react-bootstrap"

import CloseButton from "react-bootstrap/CloseButton"
import ProviderLogin from "../ProviderLogin"
import SpinLoad from "../../../components/shared/Spinner/"

const ProviderSignUp = () => {
	const [firstName, setFirstName] = React.useState("")
	const [lastName, setLastName] = React.useState("")
	const [email, setEmail] = React.useState("")
	const [confirmEmail, setConfirmEmail] = React.useState("")
	const [password, setPassword] = React.useState("")
	const [confirmPassword, setConfirmPassword] = React.useState("")
	const [phone, setPhone] = React.useState("")
	const [providerType, setProviderType] = React.useState("")
	const [gender, setGender] = React.useState("")
	const [location, setLocation] = React.useState("")
	const [check, setCheck] = React.useState("")
	const [show, setShow] = React.useState(false)
	const [busy, setBusy] = React.useState(false)
	const [show2, setShow2] = React.useState(false)
	const [show3, setShow3] = React.useState(false)
	const [modalErrorMessage, setModalErrorMessage] = React.useState("")
	const [modalErrorVisible, setModalErrorVisible] = React.useState(false)
	const [getGender, setGetGender] = React.useState([])
	const [getLoc, setGetLoc] = React.useState([])
	const [genId, setGenId] = React.useState("")
	const [locId, setLocId] = React.useState("")

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const handleClose2 = () => setShow2(false)
	const handleShow2 = () => setShow2(true)

	const handleClose3 = () => setShow3(false)
	const handleShow3 = () => setShow3(true)

	useEffect(() => {
		getGender.map((item) => {
			if (item.title === gender) {
				setGenId(item.id)
			}
		})
	}, [gender])

	useEffect(() => {
		getLoc.map((item) => {
			if (item.title === location) {
				setLocId(item.id)
			}
		})
	}, [location])

	useEffect(() => {
		axios
			.get("https://api.galeahealth.com/app/gender", {})
			.then((res) => {
				setGetGender(res.data)
			})
			.catch((err) => {
				console.log(err)
			})

		axios
			.get("https://api.galeahealth.com/app/location", {})
			.then((res) => {
				setGetLoc(res.data)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	const registerUser = () => {
		if (
			firstName &&
			lastName &&
			email &&
			confirmEmail &&
			password &&
			confirmPassword &&
			phone &&
			providerType &&
			providerType !== "0"
		) {
			setBusy(true)
			const formDetails = {
				first_name: firstName,
				last_name: lastName,
				email: email,
				phone: phone,
				password: password,
				user_type: providerType,
				location_id: locId,
				gender_id: genId,
				address: "",
				// lat: 91,
				// lng: 181,
				is_receive_new_articles: 0,
				is_notify_new_booking: 0,
			}

			axios
				.post("https://api.galeahealth.com/app/user/register", formDetails)
				.then((res) => {
					// if (res.status === 201) {
					setShow(true)
					setFirstName("")
					setLastName("")
					setEmail("")
					setConfirmEmail("")
					setPassword("")
					setConfirmPassword("")
					setPhone("")
					setProviderType("")
					setGender("")
					setLocation("")
					setBusy(false)
					// }
				})
				.catch((err) => {
					setModalErrorMessage(err.response.data.message)
					setModalErrorVisible(true)
					setBusy(false)
				})
		} else {
			setShow3(true)
		}
	}

	return (
		<>
			<div className={styles.providerSignUpMain}>
				<div className={styles.providerSignUpInner}>
					{/* <div className={styles.heroMain}>
						<div className={styles.heroDiv}></div>
						<div className={styles.heroTxtMain}>
							<h1>Apply to join Our Provider Network</h1>
						</div>
					</div> */}
					{/* <section className={styles.aboutSection}>
						<div className={styles.aboutSectionInner}>
							<div className={styles.aboutImgs}>
								<div className={styles.aboutImgsInner}>
									<div className={styles.Img1Main}>
										<img src={ProfileInformation} alt="Profile Information" className={styles.Img1} />
									</div>
									<div className={styles.Img2Main}>
										<img src={Card} alt="" className={styles.Img2} />
									</div>
								</div>
							</div>
							<div className={styles.aboutDesc}>
								<div className={styles.descImgMain}>
									<img src={Decor} alt="Decor Images" className={styles.descImg} />
								</div>
								<div className={styles.descTitleMain}>
									<span className={styles.titleLine}></span>
									<h1 className={styles.titleTxt}>About You</h1>
								</div>
								<div className={styles.titleDetails}>
									<span className={styles.titleDetail1}>
										Your profile will spotlight your background, approach, and expertise.
									</span>
									<span className={styles.titleDetail2}>New clients whose needs match your profile and expertise</span>
									<span className={styles.titleDetail2}>will connect with you to start therapy or coaching.</span>
								</div>
								<div className={styles.qualification}>
									<h1 className={styles.qualTitle}>Your qualifications:</h1>
									<p className={styles.qualTxt}>
										<span className={styles.qualIcon}>
											<svg
												class=""
												focusable="false"
												style={{ width: "20px", height: "20px" }}
												aria-hidden="true"
												viewBox="0 0 24 24"
												fill="#0094E1"
												data-testid="DoneIcon"
												tabindex="-1"
												title="Done"
											>
												<path d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
											</svg>
										</span>
										Licensed by a state board to provide counseling (e.g., LCSW, LMFT, LPC, PsyD, or similar
										credentials) or certified by an accredited program to provide performance, health/nutrition, or life
										coaching (e.g. AASP Certified Mental Performance Consultant)
									</p>
									<p className={styles.qualTxt}>
										<span className={styles.qualIcon}>
											<svg
												class=""
												focusable="false"
												style={{ width: "20px", height: "20px" }}
												aria-hidden="true"
												viewBox="0 0 24 24"
												fill="#0094E1"
												data-testid="DoneIcon"
												tabindex="-1"
												title="Done"
											>
												<path d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
											</svg>
										</span>
										Experienced in individual or group counseling, coaching, or mentorship
									</p>
									<p className={styles.qualTxt}>
										<span className={styles.qualIcon}>
											<svg
												class=""
												focusable="false"
												style={{ width: "20px", height: "20px" }}
												aria-hidden="true"
												viewBox="0 0 24 24"
												fill="#0094E1"
												data-testid="DoneIcon"
												tabindex="-1"
												title="Done"
											>
												<path d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
											</svg>
										</span>
										Personal athletic background or extensive experience working with athletes{" "}
									</p>
									<p className={styles.qualTxt}>
										<span className={styles.qualIcon}>
											<svg
												class=""
												focusable="false"
												style={{ width: "20px", height: "20px" }}
												aria-hidden="true"
												viewBox="0 0 24 24"
												fill="#0094E1"
												data-testid="DoneIcon"
												tabindex="-1"
												title="Done"
											>
												<path d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
											</svg>
										</span>
										Reliable internet connection{" "}
									</p>
									<p className={styles.qualTxt}>
										<span className={styles.qualIcon}>
											<svg
												class=""
												focusable="false"
												style={{ width: "20px", height: "20px" }}
												aria-hidden="true"
												viewBox="0 0 24 24"
												fill="#0094E1"
												data-testid="DoneIcon"
												tabindex="-1"
												title="Done"
											>
												<path d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
											</svg>
										</span>
										U.S. or Canadian resident{" "}
									</p>
									<span className={styles.qualNote}>
										Note: Providers are not Galea Health employees. There is a $35 monthly subscription to be in our
										network.
									</span>
								</div>
							</div>
						</div>
					</section> */}
					{/* <section className={styles.howWorks}>
						<div className={styles.howWorksInner}>
							<h1 className={styles.applicationTitle}>Our Application Process</h1>
							<div className={styles.applicationDesc}>
								<div className={styles.descTxtMain}>
									<p className={styles.descTxt}>
										<span className={styles.descNo}>1.</span>
										Providers create an account and complete our screening
									</p>
								</div>

								<div className={styles.descTxtMain}>
									<p className={styles.descTxt}>
										<span className={styles.descNo}>2.</span>
										Providers who pass our screening criteria will be chosen to fill out their profile and continue the
										application process{" "}
									</p>
								</div>
								<div className={styles.descTxtMain}>
									<p className={styles.descTxt}>
										<span className={styles.descNo}>3.</span>
										Galea will select top providers to list in our directory for athletes{" "}
									</p>
								</div>
							</div>
							<div className={styles.curveArrowMain}>
								<img src={curveArrow} alt="Image" className={styles.curveArrowImg} />
							</div>
						</div>
					</section> */}
					<section className={styles.applySec}>
						<div className={styles.applySecInner}>
							{/* <div className={styles.forApplyWidth}>
								<div className={styles.applyImgs}>
									<div className={styles.leafImg}>
										<img src={leaf} className={styles.applyImg} />
									</div>
									<div className={styles.apply1Img}>
										<img src={apply1} className={styles.applyImg} />
									</div>
									<div className={styles.apply2Img}>
										<img src={apply2} className={styles.applyImg} />
									</div>
								</div>
							</div> */}
							<Container>
								<Row className="mt-5 pt-5 justify-content-center">
									<Col md={10}>
										<div>
											<div className={styles.applyFormInner}>
												<span className={styles.applyLine}></span>
												<h1 className={styles.applyHead}>Apply to be a provider</h1>
												<form>
													<Row>
														<Col md={6} className="mt-4">
															<input
																type="text"
																value={firstName}
																onChange={(e) => setFirstName(e.target.value)}
																placeholder="First Name*"
																className={styles.inputField}
															/>
														</Col>
														<Col md={6} className="mt-4">
															<input
																type="text"
																value={lastName}
																onChange={(e) => setLastName(e.target.value)}
																placeholder="Last Name*"
																className={styles.inputField}
															/>
														</Col>
														<Col md={6} className="mt-4">
															<input
																type="text"
																value={email}
																required
																onChange={(e) => setEmail(e.target.value)}
																placeholder="E-mail*"
																className={styles.inputField}
															/>
														</Col>
														<Col md={6} className="mt-4">
															<input
																type="text"
																value={confirmEmail}
																onChange={(e) => setConfirmEmail(e.target.value)}
																placeholder="Confirm E-mail*"
																className={styles.inputField}
															/>
														</Col>
														<Col md={6} className="mt-4">
															<input
																type="password"
																value={password}
																onChange={(e) => setPassword(e.target.value)}
																placeholder="Password"
																className={styles.inputField}
															/>
														</Col>
														<Col md={6} className="mt-4">
															<input
																type="password"
																value={confirmPassword}
																onChange={(e) => setConfirmPassword(e.target.value)}
																placeholder="Confirm-Password"
																className={styles.inputField}
															/>
														</Col>
														<Col md={6} className="mt-4">
															<input
																type="text"
																value={phone}
																onChange={(e) => setPhone(e.target.value)}
																placeholder="Phone*"
																className={styles.inputField}
															/>
														</Col>
														<Col md={6} className="mt-4">
															<select
																value={providerType}
																onChange={(e) => setProviderType(e.target.value)}
																id="primaryProviderType"
																className={styles.inputField}
															>
																<option value="0">Primary provider type*</option>
																<option value="therapist">therapist</option>
																<option value="healthCoach">Health Coach</option>
																<option value="lifeCoach">Life Coach</option>
																<option value="nutritionist">Nutritionist</option>
																<option value="mentor">Mentor</option>
																<option value="personalTrainer">Personal Trainer</option>
																<option value="mentalPerformanceCoach">Mental Performance Coach</option>
																<option value="sportsCoach">Sports Coach</option>
															</select>
														</Col>
														<Col md={6} className="mt-4">
															<select
																value={gender}
																onChange={(e) => setGender(e.target.value)}
																id="gender"
																className={styles.inputField}
															>
																<option value="Gender">Gender*</option>
																{getGender &&
																	getGender.map((item, index) => (
																		<option key={index} id={item.id}>
																			{item.title}
																		</option>
																	))}
															</select>
														</Col>
														<Col md={6} className="mt-4">
															<select
																value={location}
																onChange={(e) => setLocation(e.target.value)}
																id="location"
																className={styles.inputField}
															>
																<option value="Location">Location*</option>
																{getLoc &&
																	getLoc.map((item, index) => (
																		<option key={index} id={item.id}>
																			{item.title}
																		</option>
																	))}
															</select>
														</Col>
														<Col md={6} className="mt-4">
															<span className={styles.agreeText}>
																<input value={check} onChange={(e) => setCheck(e.target.value)} type="checkbox" /> I
																agree to <label className={styles.agreeLabel}>the terms of use</label> regarding my data
															</span>
														</Col>
														<Row className="justify-content-center">
															<Col md={4} className="mt-4">
																<button type="button" onClick={registerUser} className={styles.applySendButton}>
																	Send
																</button>
																{busy && (
																	<div
																		className="d-flex justify-content-center align-items-center"
																		style={{ width: "100%", height: "75%" }}
																	>
																		<SpinLoad />
																	</div>
																)}
																<div className="text-center">
																	<span className={`${styles.signInTxt} ${styles.agreeText}`}>
																		Already have account ?{" "}
																		<div onClick={handleShow2} className={styles.signInLink}>
																			{" "}
																			- Signin
																		</div>
																	</span>
																</div>
															</Col>
														</Row>
													</Row>

													{/* <div className={styles.inputMain}>
											<input
												type="text"
												value={firstName}
												onChange={(e) => setFirstName(e.target.value)}
												placeholder="First Name*"
												className={styles.inputField}
											/>
										</div> */}
													{/* <div className={styles.inputMain}>
											<input
												type="text"
												value={lastName}
												onChange={(e) => setLastName(e.target.value)}
												placeholder="Last Name*"
												className={styles.inputField}
											/>
										</div> */}
													{/* <div className={styles.inputMain}>
											<input
												type="text"
												value={email}
												required
												onChange={(e) => setEmail(e.target.value)}
												placeholder="E-mail*"
												className={styles.inputField}
											/>
										</div> */}
													{/* <div className={styles.inputMain}>
											<input
												type="text"
												value={confirmEmail}
												onChange={(e) => setConfirmEmail(e.target.value)}
												placeholder="Confirm E-mail*"
												className={styles.inputField}
											/>
										</div> */}
													{/* <div className={styles.inputMain}>
											<input
												type="password"
												value={password}
												onChange={(e) => setPassword(e.target.value)}
												placeholder="Password"
												className={styles.inputField}
											/>
										</div> */}
													{/* <div className={styles.inputMain}>
											<input
												type="password"
												value={confirmPassword}
												onChange={(e) => setConfirmPassword(e.target.value)}
												placeholder="Confirm-Password"
												className={styles.inputField}
											/>
										</div> */}
													{/* <div className={styles.inputMain}>
											<input
												type="text"
												value={phone}
												onChange={(e) => setPhone(e.target.value)}
												placeholder="Phone*"
												className={styles.inputField}
											/>
										</div> */}
													{/* <div className={styles.inputMain}>
											<select
												value={providerType}
												onChange={(e) => setProviderType(e.target.value)}
												id="primaryProviderType"
												className={styles.inputField}
											>
												<option value="therapist">Primary provider type*</option>
												<option value="therapist">therapist</option>
												<option value="healthCoach">Health Coach</option>
												<option value="lifeCoach">Life Coach</option>
												<option value="nutritionist">Nutritionist</option>
												<option value="mentor">Mentor</option>
												<option value="personalTrainer">Personal Trainer</option>
												<option value="mentalPerformanceCoach">Mental Performance Coach</option>
												<option value="sportsCoach">Sports Coach</option>
											</select>
										</div> */}
													{/* <div className={styles.inputMain}>
											<select
												value={gender}
												onChange={(e) => setGender(e.target.value)}
												id="gender"
												className={styles.inputField}
											>
												<option value="Female">Gender*</option>
												<option value="female">Female</option>
												<option value="male">Male</option>
												<option value="nonBinary">Non-binary</option>
											</select>
										</div> */}
													{/* <div className={styles.inputMain}>
											<select
												value={location}
												onChange={(e) => setLocation(e.target.value)}
												id="location"
												className={styles.inputField}
											>
												<option value="Alabama">Location*</option>
												<option value="Alabama">Alabama</option>
												<option value="Alaska"> Alaska </option>
												<option value="Arizona"> Arizona </option>
												<option value="Arkansas"> Arkansas </option>
												<option value="California"> California </option>
												<option value="Colorado">Colorado </option>
												<option value="Connecticut"> Connecticut </option>
												<option value="Delaware"> Delaware </option>
											</select>
										</div> */}
													{/* <div className={styles.inputMain}>
											<span className={styles.agreeText}>
												<input value={check} onChange={(e) => setCheck(e.target.value)} type="checkbox" /> I agree to{" "}
												<label className={styles.agreeLabel}>the terms of use</label> regarding my data
											</span>
										</div> */}
													{/* <div className={styles.inputMain}>
											<button type="button" onClick={registerUser}   className={styles.applySendButton}>
												send
											</button>
										</div> */}
												</form>
												{/* <span className={`${styles.signInTxt} ${styles.agreeText}`}>
										Already have account ? <div onClick={handleShow2} className={styles.signInLink}> - Signin</div>
									</span> */}
											</div>
										</div>
									</Col>
								</Row>
							</Container>
						</div>
					</section>
					{/* <section className={styles.emailSec}>
						<div className={styles.emailInner}>
							<div className={styles.emailImageMain}>
								<img src={Asset1} className={styles.emailImage} />
							</div>
							<div className={styles.emailDesc}>
								<h1 className={styles.emailHead}>Interested in learning more?</h1>
								<h5 className={styles.emailTxt}>E-mail us with questions, concerns, or suggestions.</h5>
								<button className={styles.emailBtn}>Send Email</button>
							</div>
						</div>
					</section> */}
					{/* <section className={styles.footerMain}>
                        <div className={styles.footerList}>
                            <div className={styles.listHead}>
                                <h1>Links</h1>
                            </div>
                            <div className={styles.listContent}>
                                <span className={styles.listTxt}>Home</span>
                                <span className={styles.listTxt}>Providers</span>
                                <span className={styles.listTxt}>Advocates</span>

                            </div>
                        </div>
                        <div className={styles.footerList}>
                            <div className={styles.listHead}>
                                <h1></h1>
                            </div>
                            <div className={styles.listContent}>
                                <span className={styles.listTxt}>Blog</span>
                                <span className={styles.listTxt}>Contact</span>
                                <span className={styles.listTxt}>FAQ</span>

                            </div>
                        </div>
                        <div className={styles.footerList}>
                            <div className={styles.listHead}>
                                <h1>Sign up</h1>
                            </div>
                            <div className={styles.listContent}>
                                <span className={styles.listTxt}>Sign up as a Therapist</span>
                                <span className={styles.listTxt}>Sign up as an Advocate</span>
                                <span className={styles.listTxt}>Sign up as an Athlete</span>

                            </div>
                        </div>
                        <div className={styles.footerList}>
                            <div className={styles.listHead}>
                                <h1>Contact</h1>
                            </div>
                            <div className={styles.listContent}>
                                <span className={styles.listTxt}>team@galeahealth.com</span>

                                <span className={styles.listLinks}>
                                    <a className={styles.listLinkMain}>
                                        <img src={Facebook} alt="Facebook" className={styles.listLink}/>
                                    </a>
                                    <a className={styles.listLinkMain}>
                                        <img src={Instagram} alt="Instagram" className={styles.listLink}/>
                                    </a>
                                    <a className={styles.listLinkMain}>
                                        <img src={twitter} alt="twitter" className={styles.listLink}/>
                                    </a>
                                    <a className={styles.listLinkMain}>
                                        <img src={Linkedin} alt="Linkedin" className={styles.listLink}/>
                                    </a>
                                </span>

                            </div>
                        </div>
                    </section> */}
				</div>
			</div>
			<Modal centered size="lg" show={show} onHide={handleClose}>
				<Modal.Body>
					<div className="text-center pb-3 pt-5">
						<span className="patti"></span>
						<h6 className="patti">Galea Health</h6>
						<p>Please check your email in order to confirm your registration.</p>
						<Button
							className="btn btn-primary"
							style={{ backgroundColor: "#007bff", color: "#fff" }}
							onClick={handleClose}
						>
							OK
						</Button>
					</div>
				</Modal.Body>
			</Modal>
			<Modal centered size="lg" show={modalErrorVisible} onHide={() => setModalErrorVisible(false)}>
				<Modal.Body>
					<div className="text-center pb-3 pt-5">
						<span className="patti"></span>
						<h6 className="patti">Galea Health</h6>
						<p>{modalErrorMessage}</p>
						<Button
							className="btn btn-primary"
							style={{ backgroundColor: "#007bff", color: "#fff" }}
							onClick={() => setModalErrorVisible(false)}
						>
							OK
						</Button>
					</div>
				</Modal.Body>
			</Modal>
			<Modal centered size="lg" show={show3} onHide={handleClose3}>
				<Modal.Body>
					<div className="text-center pb-3 pt-5">
						<span className="patti"></span>
						<h6 className="patti">Galea Health</h6>
						<p>Please fill out all fields.</p>
						<Button
							className="btn btn-primary"
							style={{ backgroundColor: "#007bff", color: "#fff" }}
							onClick={handleClose3}
						>
							OK
						</Button>
					</div>
				</Modal.Body>
			</Modal>
			<Modal className="prvMdlWrap" size="lg" centered={true} show={show2} onHide={handleClose}>
				<Modal.Body>
					<img src={leaf} className="leafCls" />
					<div>
						<CloseButton onClick={handleClose2} className="prvMdlClose" />
					</div>
					<ProviderLogin />
				</Modal.Body>
			</Modal>
		</>
	)
}

export default ProviderSignUp
