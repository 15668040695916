import * as React from "react"
import { Main } from "../components/layout"
import ProviderSignUp from "../components/pages/ProviderSignUp"


// markup
const ProviderSignUpPage = () => {
	return (
		<>
			<Main>
				<ProviderSignUp />
			</Main>
		</>
	)
}


export default ProviderSignUpPage
