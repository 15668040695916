import React from "react"
import Spinner from 'react-bootstrap/Spinner';

function SpinLoad() {
	return (
		<>
			<Spinner animation="border" variant="primary" role="status" style={{ width: "4rem", height: "4rem" }}>
				{/*<span className="visually-hidden">Loading...</span>*/}
			</Spinner>
		</>
	);
}

export default SpinLoad;
